const FacebookIcon = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="31.9546" cy="32.095" r="31.605" fill="#F8F8F8" />
      <path
        d="M39.0701 25.62C39.453 25.62 39.7655 25.5091 40.0073 25.2874C40.2492 25.0455 40.3702 24.723 40.3702 24.3199V19.1801C40.3702 18.7769 40.2492 18.4645 40.0073 18.2428C39.7655 18.0009 39.453 17.88 39.0701 17.88H35.2001C34.1318 17.88 33.1341 18.0815 32.2069 18.4847C31.2595 18.8878 30.4331 19.4421 29.7277 20.1476C29.0222 20.853 28.4679 21.6794 28.0648 22.6268C27.6616 23.554 27.4601 24.5517 27.4601 25.62V28.1899H24.8902C24.487 28.1899 24.1645 28.3108 23.9227 28.5527C23.7009 28.7946 23.5901 29.107 23.5901 29.49V34.6601C23.5901 35.043 23.7009 35.3554 23.9227 35.5973C24.1645 35.819 24.487 35.9299 24.8902 35.9299H27.4601V44.97C27.4601 45.3529 27.5709 45.6654 27.7927 45.9072C28.0345 46.1491 28.357 46.27 28.7602 46.27H33.9C34.3031 46.27 34.6155 46.1491 34.8373 45.9072C35.0791 45.6654 35.2001 45.3529 35.2001 44.97V35.9299H37.77C38.0925 35.9299 38.3747 35.8392 38.6166 35.6578C38.8584 35.4764 39.0096 35.2244 39.0701 34.9019L40.3702 29.7621C40.4306 29.5605 40.4306 29.369 40.3702 29.1876C40.3298 28.9861 40.2391 28.7845 40.098 28.5829C39.9771 28.4015 39.8259 28.2907 39.6445 28.2504C39.4631 28.2101 39.2716 28.1899 39.0701 28.1899H35.2001V25.62H39.0701ZM33.9 30.7901H37.4072L36.742 33.36H33.9C33.517 33.36 33.2046 33.4809 32.9627 33.7228C32.741 33.9445 32.6302 34.2569 32.6302 34.6601V43.6699H30.03V34.6601C30.03 34.2569 29.9091 33.9445 29.6672 33.7228C29.4455 33.4809 29.1431 33.36 28.7602 33.36H26.16V30.7901H28.7602C29.1431 30.7901 29.4455 30.6691 29.6672 30.4272C29.9091 30.1854 30.03 29.8729 30.03 29.49V25.62C30.03 24.209 30.5339 22.9997 31.5417 21.9919C32.5697 20.9639 33.7891 20.4499 35.2001 20.4499H37.77V23.0501H35.2001C34.4946 23.0501 33.8899 23.302 33.386 23.8059C32.8821 24.3098 32.6302 24.9145 32.6302 25.62V29.49C32.6302 29.8729 32.741 30.1854 32.9627 30.4272C33.2046 30.6691 33.517 30.7901 33.9 30.7901Z"
        fill="#444444"
      />
    </svg>
  );
};

export default FacebookIcon;
