import SallaError from "../assets/images/SallaError.svg";
import { Button } from "@mui/material";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MobileContext } from "../context/MobileContext";

const Error = () => {
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);

  return (
    <div className="result__section">
      <div className="flex">
        <div
          className="flex-1 result__section--contentSection--loading"
          style={{ marginTop: "70px" }}
        >
          <img
            style={{ textAlign: "center", margin: "0 auto" }}
            src={SallaError}
            alt=""
          />

          <h1 style={{ marginTop: "40px", marginBottom: "30px" }}>عفوا</h1>
          <h2
            style={{
              width: isMobile ? "90%" : "30%",
              margin: "30px auto 30px auto",
            }}
          >
            تحتاج تعيد الاختبار أجب بشفافية بعد القراءة، لتتمكن من معرفة شخصيتك
            التجارية.
          </h2>
          <Button
            className="ButtonComponent"
            variant="contained"
            color="primary"
            sx={{ fontSize: "18px", fontWeight: "700" }}
            onClick={() => navigate("/start")}
          >
            أعد الاختبار
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Error;
