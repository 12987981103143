import React from "react";
import "./index.css";
import "./assets/styles/main.scss";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import Start from "./pages/Start.tsx";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Result from "./pages/Result.tsx";
import Error from "./pages/Error.tsx";
import { MobileProvider } from "./context/MobileContext.tsx";
import Figures from "./pages/Figures.tsx";
import NotFound from "./pages/NotFound.tsx";
import Loader from "./pages/Loader.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/start",
    element: <Start />,
  },
  {
    path: "/result/:type",
    element: <Result />,
  },
  {
    path: "/loader/:page",
    element: <Loader />,
  },
  {
    path: "/abnormal_behavior",
    element: <Error />,
  },
  {
    path: "/figures",
    element: <Figures />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <MobileProvider>
      <RouterProvider router={router} />
    </MobileProvider>
  </React.StrictMode>,
);
