import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MediaComponent from "../components/MediaComponent";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { MobileContext } from "../context/MobileContext";
import TwitterIcon from "../components/TwitterIcon";
import WhatsappIcon from "../components/WhatsappIcon";
import FacebookIcon from "../components/FacebookIcon";
import LinkIcon from "../components/LinkIcon";
import EmailIcon from "../components/EmailIcon";
import CloseIcon from "../components/CloseIcon";
import TypeContent, { TypeContentT } from "../modules/TypeContent";
import SallaLogo from "../assets/images/SallaLogo.svg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

const Result = () => {
  const navigate = useNavigate();
  const { type } = useParams() as unknown as { type: keyof typeof TypeContent };
  const [, setTypeImage] = useState<string | undefined>(undefined);
  const [sketchSrc, setSketchSrc] = useState<string | undefined>(undefined);
  const [character1, setCharacter1] = useState<string | undefined>(undefined);
  const [character2, setCharacter2] = useState<string | undefined>(undefined);
  const [character1Organization, setCharacter1Organization] = useState<
    string | undefined
  >(undefined);
  const [character2Organization, setCharacter2Organization] = useState<
    string | undefined
  >(undefined);
  const [movieSrc, setMovieSrc] = useState<string | undefined>(undefined);
  const [podcastSrc, setPodcatSrc] = useState<string | undefined>(undefined);
  const [bookSrc, setBookSrc] = useState<string | undefined>(undefined);
  const [currentContent, setCurrentContent] = useState<TypeContentT>();
  const isMobile = useContext(MobileContext);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!Object.prototype.hasOwnProperty.call(TypeContent, type)) {
      navigate("/notfound");
    }
  }, [type]);

  useEffect(() => {
    const loadImage = async () => {
      try {
        const sketchImage = await import(
          `../assets/resultsCharacters/${type}/sketch.png`
        );
        const character1Image = await import(
          `../assets/resultsCharacters/${type}/1.png`
        );
        const character2Image = await import(
          `../assets/resultsCharacters/${type}/2.png`
        );
        const character1organizationImage = await import(
          `../assets/resultsCharacters/${type}/1c.png`
        );
        const character2organizationImage = await import(
          `../assets/resultsCharacters/${type}/2c.png`
        );
        const movieImage = await import(
          `../assets/resultsCharacters/${type}/movie.png`
        );
        const podcastImage = await import(
          `../assets/resultsCharacters/${type}/podcast.png`
        );
        const bookImage = await import(
          `../assets/resultsCharacters/${type}/book.png`
        );
        const typeImageOg = await import(
          `../assets/images/cards/${type}_card.jpg`
        );

        setSketchSrc(sketchImage.default);
        setCharacter1(character1Image.default);
        setCharacter2(character2Image.default);
        setCharacter1Organization(character1organizationImage.default);
        setCharacter2Organization(character2organizationImage.default);
        setMovieSrc(movieImage.default);
        setPodcatSrc(podcastImage.default);
        setBookSrc(bookImage.default);
        setCurrentContent(TypeContent[`${type}`]);
        setTypeImage(typeImageOg);
      } catch (error) {
        console.error("Failed to load image:", error);
      }
    };

    loadImage();
  }, [type]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const ogType = type || 'adventurer';
  // const imageUrl = typeImage;
  const title = "جربت اختبار الشخصيات التجارية من سلة وهذه #شخصيتي_التجارية";
  // const description = 'تعرف على ملامح شخصیتك التجاریة وكیفیة استثمارها';
  // const ogImageUrl = imageUrl || sketchSrc;
  const ogTitle = title || "اكتشف شخصيتك التجارية";
  // const ogDescription = description || 'تعرف على ملامح شخصیتك التجاریة وكیفیة استثمارها';

  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      ogTitle,
    )}&url=${encodeURIComponent("https://personality.salla.com/")}`;
    window.open(twitterShareUrl, "_blank");
  };

  const shareOnWhatsApp = () => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${ogTitle} https://personality.salla.com/`,
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.hostname,
    )}`;
    window.open(facebookShareUrl, "_blank");
  };

  const shareLink = () => {
    const contentToCopy = `${ogTitle} - https://${window.location.hostname}`;
    navigator.clipboard.writeText(contentToCopy);
  };

  const shareOnEmail = () => {
    const emailSubject = encodeURIComponent(ogTitle);
    const emailBody = encodeURIComponent(
      `${ogTitle}\n\n${window.location.hostname}`,
    );
    window.location.hostname = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  };

  const typeFileName = {
    adventurer: "التاجر المغامر - اكتشف شخصيتك التجارية - سلة",
    dreamer: "التاجر الحالم - اكتشف شخصيتك التجارية - سلة",
    wise: "التاجر الحكيم - اكتشف شخصيتك التجارية - سلة",
    sober: "التاجر الرزين - اكتشف شخصيتك التجارية - سلة",
    artist: "التاجر الفنان - اكتشف شخصيتك التجارية - سلة",
    fighter: "التاجر المكافح - اكتشف شخصيتك التجارية - سلة",
  };

  const handleDownload = async () => {
    if (type) {
      const PDF_File = await import(`../assets/pdfs/${type}/book.pdf`);
      const pdfUrl = PDF_File.default;
      const link = document.createElement("a");
      link.download = typeFileName[type];
      link.href = pdfUrl;
      link.click();
    }
  };

  return (
    <div className="result__section">
      {isMobile ? (
        <div className="">
          <h1 className="result__section--contentSection--title mb-4">
            <img className="sallaMobileLogo" src={SallaLogo} alt="" />
            {currentContent && currentContent.title}
          </h1>
          <img src={sketchSrc} alt="" />
        </div>
      ) : null}

      <div className="flex">
        <div className="flex-auto result__section--contentSection">
          <h1 className="result__section--contentSection--title mb-4">
            عن {currentContent?.title} :
          </h1>
          <h1 className="result__section--contentSection--typo">
            {currentContent?.desc}
          </h1>
          <h1 className="result__section--contentSection--title mt-20 mb-4">
            سماته الأساسية:
          </h1>
          <div className="flex flex-wrap justify-center text-center m-auto result__section--contentSection--characteristics">
            {currentContent?.characteristics.split("، ").map((c) => (
              <div
                key={c}
                className="w-full sm:w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/3"
              >
                {c}
              </div>
            ))}
          </div>
          <h1 className="result__section--contentSection--title  mt-20 mb-4">
            شخصيات تشبهك:
          </h1>
          <div className="lg:flex justify-center result__section--contentSection--chars">
            <div className="flex-1 characterImage">
              <img src={character1} alt="" />
              <div className="__org">
                <img src={character1Organization} alt="" />
              </div>
              <div className="__name">{currentContent?.characters[0].name}</div>
            </div>
            <div className="flex-1 characterImage">
              <img src={character2} alt="" />
              <div className="__org">
                <img src={character2Organization} alt="" />
              </div>
              <div className="__name">{currentContent?.characters[1].name}</div>
            </div>
          </div>
          <div className="__knowMoreButton">
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ fontSize: "16px", width: 300 }}
              style={isMobile ? { margin: "0px auto" } : { margin: "0 auto" }}
              onClick={() =>
                navigate("/figures", { state: { from: "result" } })
              }
            >
              اعرف اكثر عن الشخصيات الملهمة
            </Button>
          </div>
          <h1 className="result__section--contentSection--title  mt-20 mb-4">
            مساحة إلهام:
          </h1>

          {isMobile ? (
            <Swiper
              className="swiperStyle"
              spaceBetween={-10}
              slidesPerView={1.5}
              dir="rtl"
            >
              <SwiperSlide>
                <MediaComponent
                  src={bookSrc}
                  type={"book"}
                  title={currentContent?.book.title}
                  url=""
                />
              </SwiperSlide>
              <SwiperSlide>
                <MediaComponent
                  src={podcastSrc}
                  type={"podcast"}
                  title={currentContent?.podcast.title}
                  url={currentContent?.movie.url}
                />
              </SwiperSlide>
              <SwiperSlide>
                <MediaComponent
                  src={movieSrc}
                  type={"movie"}
                  title={currentContent?.movie.title}
                  url={currentContent?.movie.url}
                />
              </SwiperSlide>
            </Swiper>
          ) : (
            <div className="flex justify-center gap-5">
              <MediaComponent
                src={bookSrc}
                type={"book"}
                title={currentContent?.book.title}
                url=""
              />
              <MediaComponent
                src={podcastSrc}
                type={"podcast"}
                title={currentContent?.podcast.title}
                url={currentContent?.movie.url}
              />
              <MediaComponent
                src={movieSrc}
                type={"movie"}
                title={currentContent?.movie.title}
                url={currentContent?.movie.url}
              />
            </div>
          )}

          <Stack
            direction={"column"}
            mt={5}
            mb={5}
            gap={3}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ fontSize: "16px" }}
              onClick={handleClickOpen}
            >
              مشاركة النتيجة
            </Button>
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ background: "white!important", fontSize: "16px" }}
              onClick={() => navigate("/start")}
            >
              اختبر من جديد
            </Button>
            <Button
              className="ButtonComponentText"
              variant="text"
              color="primary"
              sx={{
                background: "transparent!important",
                boxShadow: "none!important",
                fontSize: "16px",
              }}
              onClick={handleDownload}
            >
              تحميل النتيجة PDF
            </Button>
          </Stack>
        </div>

        {isMobile ? null : (
          <div className="flex-auto result__section--characterSection">
            <div className="result__section--characterSection--sticky">
              <h1>{currentContent && currentContent.title}</h1>
              <img src={sketchSrc} alt="" />
            </div>
          </div>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="salla-dialog"
        >
          <DialogTitle id="alert-dialog-title" className="--bg-salla">
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent>
            <h1 className="result__section--contentSection--title mt-10">
              شارك أصدقائك
            </h1>
            <DialogContentText
              id="alert-dialog-description"
              className="--salla-dialog-content"
            >
              يمكنك مشاركة مع جمهورك وأصدقائك عبر مواقع التواصل الاجتماعي
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack
              className="share-on-socialmedia-buttons"
              gap={isMobile ? 0 : 2}
              direction={"row"}
              margin={"20px auto"}
              justifyContent={"space-around"}
              flexWrap={"wrap"}
            >
              <Button onClick={shareOnWhatsApp}>
                <WhatsappIcon />
              </Button>
              <Button onClick={shareOnTwitter}>
                <TwitterIcon />
              </Button>
              <Button onClick={shareOnFacebook}>
                <FacebookIcon />
              </Button>
              <Button onClick={shareLink}>
                <LinkIcon />
              </Button>
              <Button onClick={shareOnEmail}>
                <EmailIcon />
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default Result;
