import SallaError from "../assets/images/SallaError.svg";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="result__section">
        <div className="flex">
          <div
            className="flex-1 result__section--contentSection--loading"
            style={{ marginTop: "70px" }}
          >
            <img
              style={{ textAlign: "center", margin: "0 auto" }}
              src={SallaError}
              alt=""
            />

            <h1 style={{ marginTop: "40px", marginBottom: "30px" }}>عفوا</h1>
            <h2 style={{ width: "30%", margin: "30px auto 30px auto" }}>
              هذه الصفحة غير موجودة
            </h2>
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ fontSize: "18px", fontWeight: "700" }}
              onClick={() => navigate("/")}
            >
              عودة
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
