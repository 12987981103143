import { useContext, useState } from "react";
import {
  Box,
  Container,
  Button,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Radio,
  LinearProgress,
  linearProgressClasses,
  Typography,
  Stack,
} from "@mui/material";
import NextIcon from "../components/NextIcon";
import { useNavigate } from "react-router-dom";
import { MobileContext } from "../context/MobileContext";
import Navbar from "../components/Navbar.tsx";
type QuestionType = {
  text: string;
  weight: number;
};

type TotalType = {
  [key: string]: number;
};

type AnswerType = { answer: number; type: string; choice: string };

// const questions: QuestionType[] = [
//   // Questions 1 to 5

//   { text: "أستطیع تفویض المھام للآخرین بسهولة", weight: 4 },
//   { text: "أميل لاتخاذ قرارات جيدة سريعة دون تفكير عميق", weight: 4 },
//   { text: "في حال حدوث مشكلة غیر متوقعة أتعامل معها عن طریق التركیز علیھا والبحث عن حل", weight: 4 },
//   { text: "أفضل التجدید في أسلوب العمل أكثر من الاستقرار على أسلوب واحد", weight: 4 },
//   { text: "العمل التجاري عمل یصب في مصلحتي الشخصیة قبل الآخرین", weight: 4 },

//   // Questions 6 to 10
//   { text: "أعتبر أن الخسارة المالیة ربحًا من طرق أخرى", weight: 5 },
//   { text: "أتخذ القرارات المتعلقة بالعمل بدقة شدیدة، حتى لو أدى ذلك للتأخر", weight: 5 },
//   { text: "أفضل أن أنجح في عملي التجاري بدون أن یخسر غیري من المنافسین", weight: 5 },
//   { text: "الطرق الجدیدة في العمل مفیدة فقط إن كانت تخدم حلمي الشخصي", weight: 5 },
//   { text: "العمل التجاري یجب أن یوافق اهتماماتي الشخصیة", weight: 5 },

//   // Questions 11 to 15
//   { text: "عند إضافة منتج جدید أستطیع توقع نجاحه من فشله بدقة", weight: 6 },
//   { text: "أعتقد أن الدقة والسرعة هما مفتاح نجاح القرارات المصیریة", weight: 6 },
//   { text: "أستطیع إدارة تفویض المهام للآخرین دون أن یؤثر ذلك سلبًا على سیر العمل", weight: 6 },
//   { text: "لا یمكنني النجاح مع عدم امتلاك خطة مستقبلیة لخمس سنوات على الأقل", weight: 6 },
//   { text: "أعتقد أن لغة الأرقام أهم من نماذج العمل عند اتخاذ القرارات", weight: 6 },

//   // Questions 16 to 20
//   { text: "أفضل الأعمال الصغیرة ذات الربح القلیل أكثر من الأعمال الكبیرة ذات الربح الكبیر", weight: 7 },
//   { text: "أعتقد أن العمل التجاري الناجح یجب أن یمتلك خطة لإدارة المخاطر قبل بدء العمل", weight: 7 },
//   {
//     text: "أعتقد أن الاستمرار هو التعامل الأمثل حال حدوث تغیر مفاجئ في قطاع تجاري ما (سواء كان تغیرًا إیجابیًا أو سلبیًا)",
//     weight: 7,
//   },
//   {
//     text: "أفضل العمل بناء على النماذج والنظریات المشهورة للعمل التجاري أكثر من تجارب النجاح الشخصیة",
//     weight: 7,
//   },
//   { text: "أعتقد أن الموظفین أهم من المنتج", weight: 7 },

//   // Questions 21 to 25
//   { text: "في وقت الخسارة المالیة أكون هادئًا ومتفائلًا", weight: 8 },
//   { text: "أعتبر المنتج الذي لا یرتبط بمشاعر الناس منتجًا خاسًرا حتى إن حقق مبیعات عالیة", weight: 8 },
//   {
//     text: "أفضل الانتقال للمجالات الجدیدة في العمل التجاري مع احتمال الخسارة أكثر من البقاء على المجالات التقلیدیة",
//     weight: 8,
//   },
//   { text: "أعتقد أن كل منتج یجب أن یحل مشكلة معینة لدى الآخرین", weight: 8 },
//   { text: "أمتلك شخصیة ملهمة فیما یخص العمل والظهور الإعلامي على السواء", weight: 8 },

//   // Questions 26 to 30
//   { text: "أفضل العمل التجاري بالشراكة أكثر من العمل التجاري القائم على الأفراد", weight: 9 },
//   { text: "أفضل العمل الحركي أكثر من العمل المكتبي", weight: 9 },
//   { text: "أفضل التعامل مع الأحداث حال وقوعها أكثر من توقع الأحداث والتخطیط لها بشكل دقیق", weight: 9 },
//   { text: "في حال تكرر الخطأ بدون مبرر من موظف ذو أهمیة للعمل أفضل الاستغناء عنه وتوظیف بدیل", weight: 9 },
//   { text: "أفضل البحث عن حلول لإنجاح الفكرة الأساسیة أكثر من العمل على أفكار أخرى بدیلة", weight: 9 },
// ];

const questions: QuestionType[] = [
  // Questions 1 to 5

  { text: "أستطیع تفویض المهام للآخرین بسهولة", weight: 4 },
  { text: "أميل لاتخاذ قرارات جيدة سريعة دون تفكير عميق", weight: 4 },
  {
    text: "في حال حدوث مشكلة غیر متوقعة أتعامل معها عن طریق التركیز عليها والبحث عن حل",
    weight: 4,
  },
  {
    text: "أفضل التجدید في أسلوب العمل أكثر من الاستقرار على أسلوب واحد",
    weight: 4,
  },
  { text: "العمل التجاري عمل یصب في مصلحتي الشخصیة قبل الآخرین", weight: 4 },

  // Questions 6 to 10
  { text: "أعتبر أن الخسارة المالیة ربحًا من طرق أخرى", weight: 4 },
  {
    text: "أتخذ القرارات المتعلقة بالعمل بدقة شدیدة، حتى لو أدى ذلك للتأخر",
    weight: 4,
  },
  {
    text: "أفضل أن أنجح في عملي التجاري بدون أن یخسر غیري من المنافسین",
    weight: 4,
  },
  {
    text: "الطرق الجدیدة في العمل مفیدة فقط إن كانت تخدم حلمي الشخصي",
    weight: 4,
  },
  { text: "العمل التجاري یجب أن یوافق اهتماماتي الشخصیة", weight: 4 },

  // Questions 11 to 15
  { text: "عند إضافة منتج جدید أستطیع توقع نجاحه من فشله بدقة", weight: 4 },
  {
    text: "أعتقد أن الدقة والسرعة هما مفتاح نجاح القرارات المصیریة",
    weight: 4,
  },
  {
    text: "أستطیع إدارة تفویض المهام للآخرین دون أن یؤثر ذلك سلبًا على سیر العمل",
    weight: 4,
  },
  {
    text: "لا یمكنني النجاح مع عدم امتلاك خطة مستقبلیة لخمس سنوات على الأقل",
    weight: 4,
  },
  {
    text: "أعتقد أن لغة الأرقام أهم من نماذج العمل عند اتخاذ القرارات",
    weight: 4,
  },

  // Questions 16 to 20
  {
    text: "أفضل الأعمال الصغیرة ذات الربح القلیل أكثر من الأعمال الكبیرة ذات الربح الكبیر",
    weight: 4,
  },
  {
    text: "أعتقد أن العمل التجاري الناجح یجب أن یمتلك خطة لإدارة المخاطر قبل بدء العمل",
    weight: 4,
  },
  {
    text: "أعتقد أن الاستمرار هو التعامل الأمثل حال حدوث تغیر مفاجئ في قطاع تجاري ما (سواء كان تغیرًا إیجابیًا أو سلبیًا)",
    weight: 4,
  },
  {
    text: "أفضل العمل بناء على النماذج والنظریات المشهورة للعمل التجاري أكثر من تجارب النجاح الشخصیة",
    weight: 4,
  },
  { text: "أعتقد أن الموظفین أهم من المنتج", weight: 4 },

  // Questions 21 to 25
  { text: "في وقت الخسارة المالیة أكون هادئًا ومتفائلًا", weight: 4 },
  {
    text: "أعتبر المنتج الذي لا یرتبط بمشاعر الناس منتجًا خاسًرا حتى إن حقق مبیعات عالیة",
    weight: 4,
  },
  {
    text: "أفضل الانتقال للمجالات الجدیدة في العمل التجاري مع احتمال الخسارة أكثر من البقاء على المجالات التقلیدیة",
    weight: 4,
  },
  { text: "أعتقد أن كل منتج یجب أن یحل مشكلة معینة لدى الآخرین", weight: 4 },
  {
    text: "أمتلك شخصیة ملهمة فیما یخص العمل والظهور الإعلامي على السواء",
    weight: 4,
  },

  // Questions 26 to 30
  {
    text: "أفضل العمل التجاري بالشراكة أكثر من العمل التجاري القائم على الأفراد",
    weight: 4,
  },
  { text: "أفضل العمل الحركي أكثر من العمل المكتبي", weight: 4 },
  {
    text: "أفضل التعامل مع الأحداث حال وقوعها أكثر من توقع الأحداث والتخطیط لها بشكل دقیق",
    weight: 4,
  },
  {
    text: "في حال تكرر الخطأ بدون مبرر من موظف ذو أهمیة للعمل أفضل الاستغناء عنه وتوظیف بدیل",
    weight: 4,
  },
  {
    text: "أفضل البحث عن حلول لإنجاح الفكرة الأساسیة أكثر من العمل على أفكار أخرى بدیلة",
    weight: 4,
  },
];

const choices: string[] = [
  "Strongly Disagree",
  "Disagree",
  "Agree",
  "Strongly Agree",
];
const merchantTypes: string[] = [
  "adventurer",
  "dreamer",
  "wise",
  "sober",
  "artist",
  "fighter",
];
// const totalType: TotalType = { adventurer: 20, dreamer: 25, wise: 30, sober: 35, artist: 40, fighter: 45 };
const totalType: TotalType = {
  adventurer: 20,
  dreamer: 20,
  wise: 20,
  sober: 20,
  artist: 20,
  fighter: 20,
};

export default function Start() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [answers, setAnswers] = useState<AnswerType[]>(
    Array(30).fill({ answer: 0, type: "", choice: "" }),
  );

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleSubmit = () => {
    calculateScore();
  };

  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const getChoice = (key: number) => {
    let choice = "";
    switch (key) {
      case 1:
        choice = "Strongly Disagree";
        break;
      case 2:
        choice = "Disagree";
        break;
      case 3:
        choice = "Agree";
        break;
      default:
        choice = "Strongly Agree";
        break;
    }
    return choice;
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const questionIndex = parseInt(name, 10);
    const answerIndex = parseInt(value, 10);
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = {
      answer: answerIndex,
      type: getType(Math.floor(questionIndex / 5)),
      choice: getChoice(answerIndex),
    };
    setAnswers(updatedAnswers);
  };

  const calculateScore = () => {
    let score = 0;
    for (let i = 0; i < answers.length; i++) {
      const answer = answers[i].answer;
      score += answer;
    }

    const typeScores: { [key: string]: number } = {};
    const answerCounts: { [key: string]: { [key: string]: number } } = {};

    answers.forEach(({ answer, type, choice }) => {
      if (!typeScores[type]) {
        typeScores[type] = 0;
        answerCounts[type] = {};
        choices.forEach((c) => (answerCounts[type][c] = 0));
      }
      typeScores[type] += answer;
      answerCounts[type][choice]++;
    });

    // const transformedObj = Object.keys(typeScores).reduce((typeScoreObject: any, type: any) => {
    //   const value = typeScores[type];
    //   const transformedValue = (value / 20) * 100;
    //   typeScoreObject[type] = transformedValue;
    //   return typeScoreObject;
    // }, {});

    // Calculate the percentage scores for each type and find the maximum score
    let maxScore = 0;
    let maxScoreType = "";

    if (
      answers.filter((answer) => answer.choice === "Strongly Disagree")
        .length === answers.length ||
      answers.filter((answer) => answer.choice === "Disagree").length ===
        answers.length ||
      answers.filter((answer) => answer.choice === "Agree").length ===
        answers.length ||
      answers.filter((answer) => answer.choice === "Strongly Agree").length ===
        answers.length
    ) {
      navigate("/abnormal_behavior");
    } else {
      Object.keys(typeScores).forEach((type) => {
        const score = (typeScores[type] / totalType[type]) * 100;
        if (score > maxScore) {
          maxScore = score;
          maxScoreType = type;
        } else if (score === maxScore) {
          // In case of a draw, compare answer counts
          const currentTypeAnswerCounts = answerCounts[type];
          const maxTypeAnswerCounts = answerCounts[maxScoreType];

          // Compare the number of "Strongly Agree" choices
          if (
            currentTypeAnswerCounts["Strongly Agree"] >
            maxTypeAnswerCounts["Strongly Agree"]
          ) {
            maxScoreType = type;
          } else if (
            currentTypeAnswerCounts["Strongly Agree"] ===
            maxTypeAnswerCounts["Strongly Agree"]
          ) {
            // Compare the number of "Agree" choices
            if (
              currentTypeAnswerCounts["Agree"] > maxTypeAnswerCounts["Agree"]
            ) {
              maxScoreType = type;
            }
          }
        }
      });
      navigate("../loader/result", { state: { type: maxScoreType } });
    }
    return score;
  };

  const activeQuestions = questions.slice(activeStep * 2, activeStep * 2 + 2);
  const progressPercentage = Math.round(
    (answers.filter((item) => item.answer !== 0).length / questions.length) *
      100,
  );

  const getType = (groupIndex: number): string => {
    const typeIndex = groupIndex % merchantTypes.length;
    return merchantTypes[typeIndex];
  };

  const getLabel = (choice: string) => {
    let label = "";
    switch (choice) {
      case "Strongly Disagree":
        label = "لا أميل بشدة";
        break;
      case "Disagree":
        label = "لا أميل";
        break;
      case "Agree":
        label = "أميل";
        break;
      default:
        label = "أميل بشدة";
        break;
    }
    return label;
  };

  const getSize = (choice: string) => {
    let size = 0;
    switch (choice) {
      case "Strongly Disagree":
        size = 45;
        break;
      case "Disagree":
        size = 55;
        break;
      case "Agree":
        size = 65;
        break;
      default:
        size = 75;
        break;
    }
    return size;
  };

  const isMobile = useContext(MobileContext);
  return (
    <>
      <Navbar></Navbar>
      <Container maxWidth="md" className="start__section">
        <Box mt={4}>
          <form>
            {activeQuestions.map((question, index) => (
              <div key={index}>
                <h3 className="start__section--question">{question.text}</h3>
                <FormControl component="fieldset">
                  <RadioGroup
                    name={`${activeStep * 2 + index}`}
                    value={answers[activeStep * 2 + index].answer}
                    onChange={handleRadioChange}
                    className="start__section--flexContainer"
                  >
                    {choices.map((choice, choiceIndex) => (
                      <FormControlLabel
                        style={{
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                        className={
                          choice === "Agree" || choice === "Disagree"
                            ? "--radioLabel --hideLabel"
                            : "--radioLabel"
                        }
                        key={index + "_" + choiceIndex}
                        value={
                          choiceIndex === 3 ? question.weight : choiceIndex + 1
                        }
                        control={
                          <Radio
                            sx={{
                              color: "#BDBDBD",
                              "&.Mui-checked": {
                                color: "#004956",
                              },
                              "& .MuiSvgIcon-root": {
                                fontSize: getSize(choice),
                              },
                            }}
                          />
                        }
                        label={getLabel(choice)}
                        labelPlacement="bottom"
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            ))}
          </form>
          <Box marginTop={"29px"} marginBottom={"29px"}>
            <Stack
              direction={isMobile ? "row" : "row"}
              justifyContent={activeStep === 0 ? "center" : "space-between"}
              width={isMobile ? "100%" : 500}
              margin={"0 auto"}
              gap={isMobile ? 10 : 0}
            >
              <Button
                startIcon={
                  <NextIcon
                    style={{ display: "inline-block", marginRight: "20px" }}
                  />
                }
                className="ButtonComponent"
                variant="contained"
                color="primary"
                sx={{ fontSize: "16px" }}
                style={
                  isMobile ? { margin: "10px auto" } : { margin: "0 auto" }
                }
                disabled={
                  answers[activeStep * 2].answer === 0 ||
                  answers[activeStep * 2 + 1].answer === 0
                }
                onClick={activeStep === 14 ? handleSubmit : handleNext}
              >
                التالي
              </Button>
              {activeStep !== 0 && (
                <Button
                  endIcon={
                    <NextIcon
                      style={{
                        transform: "rotate(180deg)",
                        display: "inline-block",
                        marginLeft: "20px",
                      }}
                    />
                  }
                  className="ButtonComponent"
                  variant="contained"
                  color="primary"
                  sx={{ background: "white!important", fontSize: "16px" }}
                  style={
                    isMobile ? { margin: "10px auto" } : { margin: "0 auto" }
                  }
                  onClick={handlePrev}
                >
                  عودة
                </Button>
              )}
            </Stack>
          </Box>
        </Box>
        <Box mt={1}>
          <LinearProgress
            sx={{
              backgroundColor: "#EEEEEE",
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: "15px",
                backgroundColor: "#004956",
              },
            }}
            style={{
              transform: "rotate(180deg)",
              width: "350px",
              height: "12.5px",
              borderRadius: "15px",
              margin: "0 auto",
            }}
            variant="determinate"
            value={
              (answers.filter((item) => item.answer !== 0).length /
                questions.length) *
              100
            }
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={1}
          paddingBottom={"70px"}
        >
          <Typography
            className="start__section--typo"
            variant="body2"
          >{`${progressPercentage}%`}</Typography>
        </Box>
      </Container>
    </>
  );
}
