const CloseIcon = () => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.40625 7.5L14.7031 12.7969C14.9062 12.9844 15.0078 13.2188 15.0078 13.5C15.0078 13.7812 14.9062 14.0156 14.7031 14.2031C14.6094 14.2969 14.5 14.375 14.375 14.4375C14.25 14.4844 14.125 14.5078 14 14.5078C13.875 14.5078 13.75 14.4844 13.625 14.4375C13.5 14.375 13.3906 14.2969 13.2969 14.2031L8 8.90625L2.70312 14.2031C2.60938 14.2969 2.5 14.375 2.375 14.4375C2.25 14.4844 2.125 14.5078 2 14.5078C1.875 14.5078 1.75 14.4844 1.625 14.4375C1.5 14.375 1.39062 14.2969 1.29688 14.2031C1.09375 14.0156 0.992188 13.7812 0.992188 13.5C0.992188 13.2188 1.09375 12.9844 1.29688 12.7969L6.59375 7.5L1.29688 2.20312C1.09375 2.01562 0.992188 1.78125 0.992188 1.5C0.992188 1.21875 1.09375 0.984375 1.29688 0.796875C1.48438 0.59375 1.71875 0.492188 2 0.492188C2.28125 0.492188 2.51562 0.59375 2.70312 0.796875L8 6.09375L13.2969 0.796875C13.4844 0.59375 13.7188 0.492188 14 0.492188C14.2812 0.492188 14.5156 0.59375 14.7031 0.796875C14.9062 0.984375 15.0078 1.21875 15.0078 1.5C15.0078 1.78125 14.9062 2.01562 14.7031 2.20312L9.40625 7.5Z"
        fill="#004956"
      />
    </svg>
  );
};

export default CloseIcon;
