import { useEffect, useState } from "react";
import Character1adventurer from "../assets/resultsCharacters/adventurer/1.png";
import Character2adventurer from "../assets/resultsCharacters/adventurer/2.png";

import Character1artist from "../assets/resultsCharacters/artist/1.png";
import Character2artist from "../assets/resultsCharacters/artist/2.png";
import Character3artist from "../assets/resultsCharacters/artist/3.png";

import Character1dreamer from "../assets/resultsCharacters/dreamer/1.png";
import Character2dreamer from "../assets/resultsCharacters/dreamer/2.png";

import Character1fighter from "../assets/resultsCharacters/fighter/1.png";
import Character2fighter from "../assets/resultsCharacters/fighter/2.png";
import Character3fighter from "../assets/resultsCharacters/fighter/3.png";

import Character1sober from "../assets/resultsCharacters/sober/1.png";
import Character2sober from "../assets/resultsCharacters/sober/2.png";
import Character3sober from "../assets/resultsCharacters/sober/3.png";

import Character1wise from "../assets/resultsCharacters/wise/1.png";
import Character2wise from "../assets/resultsCharacters/wise/2.png";
import Character3wise from "../assets/resultsCharacters/wise/3.png";
// import Character4wise from "../assets/resultsCharacters/wise/4.png";

import Character1adventurerOrg from "../assets/resultsCharacters/adventurer/1c.png";
import Character2adventurerOrg from "../assets/resultsCharacters/adventurer/2c.png";

import Character1artistOrg from "../assets/resultsCharacters/artist/1c.png";
import Character2artistOrg from "../assets/resultsCharacters/artist/2c.png";
import Character3artistOrg from "../assets/resultsCharacters/artist/3c.png";

import Character1dreamerOrg from "../assets/resultsCharacters/dreamer/1c.png";
import Character2dreamerOrg from "../assets/resultsCharacters/dreamer/2c.png";

import Character1fighterOrg from "../assets/resultsCharacters/fighter/1c.png";
import Character2fighterOrg from "../assets/resultsCharacters/fighter/2c.png";
import Character3fighterOrg from "../assets/resultsCharacters/fighter/3c.png";

import Character1soberOrg from "../assets/resultsCharacters/sober/1c.png";
import Character2soberOrg from "../assets/resultsCharacters/sober/2c.png";
import Character3soberOrg from "../assets/resultsCharacters/sober/3c.png";

import Character1wiseOrg from "../assets/resultsCharacters/wise/1c.png";
import Character2wiseOrg from "../assets/resultsCharacters/wise/2c.png";
import Character3wiseOrg from "../assets/resultsCharacters/wise/3c.png";
// import Character4wiseOrg from "../assets/resultsCharacters/wise/4c.png";

import TypeContent from "../modules/TypeContent";

const allCharacters: typeof TypeContent.adventurer.characters = [];

// Iterate through each type (adventurer, dreamer, sober, wise, artist, fighter)
Object.values(TypeContent).forEach((type) => {
  // Iterate through each character within the type
  type.characters.forEach((character) => {
    allCharacters.push(character);
  });
});

const Figure = (props: {
  character: (typeof TypeContent.adventurer.characters)[0];
}) => {
  const [characterImage, setCharacterImage] = useState<string | undefined>(
    undefined,
  );
  const [organizationImage, setOrganizationImage] = useState<
    string | undefined
  >(undefined);

  useEffect(() => {
    let img;
    let orgImg;
    switch (props.character.name) {
      case "فيصل الخميسي":
        img = Character1adventurer;
        orgImg = Character1adventurerOrg;
        break;
      case "إيلون ماسك":
        img = Character2adventurer;
        orgImg = Character2adventurerOrg;
        break;
      case "أضوى الدخيل":
        img = Character1dreamer;
        orgImg = Character1dreamerOrg;
        break;
      case "والت ديزني":
        img = Character2dreamer;
        orgImg = Character2dreamerOrg;
        break;
      case "رامي أبو غزالة":
        img = Character1sober;
        orgImg = Character1soberOrg;
        break;
      case "لبنى العليان":
        img = Character2sober;
        orgImg = Character2soberOrg;
        break;
      case "جيف بيزوس":
        img = Character3sober;
        orgImg = Character3soberOrg;
        break;
      case "الوليد بن طلال":
        img = Character1wise;
        orgImg = Character1wiseOrg;
        break;
      case "ياسر الرميان":
        img = Character2wise;
        orgImg = Character2wiseOrg;
        break;
      case "د. غادة المطيري":
        img = Character3wise;
        orgImg = Character3wiseOrg;
        break;
      case "ريم قراش":
        img = Character1artist;
        orgImg = Character1artistOrg;
        break;
      case "ستيف جوبز":
        img = Character2artist;
        orgImg = Character2artistOrg;
        break;
      case "برنار أرنو":
        img = Character3artist;
        orgImg = Character3artistOrg;
        break;
      case "محمد الدوسري":
        img = Character1fighter;
        orgImg = Character1fighterOrg;
        break;
      case "سام والتن":
        img = Character2fighter;
        orgImg = Character2fighterOrg;
        break;
      case "محمد يوسف ناغي":
        img = Character3fighter;
        orgImg = Character3fighterOrg;
        break;
    }
    setCharacterImage(img);
    setOrganizationImage(orgImg);
  }, []);

  return (
    <div key={props.character?.name} className="flex-1 characterImage">
      <img src={characterImage} alt="" />
      <div className="__org">
        <img src={organizationImage} alt="" />
      </div>
      <div className="__name">{props.character?.name}</div>
      <div className="__bio">{props.character?.bio}</div>
    </div>
  );
};

export default Figure;
