import SallaLogo from "../assets/images/SallaLogo.svg";

const Navbar = () => {
  return (
    <div className="navbar flex justify-between items-center">
      <div className="navbar--content">
        <h6>اكتشف شخصيتك التجارية</h6>
      </div>
      <div>
        <a href="/">
          <img alt="منصة سلة للتجارة الإلكترونية" src={SallaLogo} />
        </a>
      </div>
    </div>
  );
};

export default Navbar;
