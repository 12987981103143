export interface TypeContentT {
  title: string;
  desc: string;
  slogan: string;
  characteristics: string;
  characters: Character[];
  movie: Movie;
  podcast: Podcast;
  book: Book;
}

interface Character {
  name: string;
  bio: string;
}

interface Movie {
  title: string;
  url: string;
}

interface Podcast {
  title: string;
  url: string;
}

interface Book {
  title: string;
}

const TypeContent = {
  adventurer: {
    title: "التاجر المغامر",
    plural: "المغامرون",
    slogan: "انت مغامر إذا انت المستقبل",
    desc: `هو تاجر حاسم وواثق، یستطیع تفویض المهام للآخرین بسهولة، ویمیل لاتخاذ قرارات سریعة ناجعة دون تفكیر، ویفضل
            مواجهة المخاطر على الهروب منها، ویمكنه تجربة طرق عمل جدیدة دون قلق، ویفضل أن یكون عمله مبني على اهتمامات إنسانیة (اهتماماته/اهتمامات الآخرین)`,
    characteristics: "اجتماعي، حاسم، ثقته عالیة بنفسه، یحب المجازفة، عاطفي",
    characters: [
      {
        name: "فيصل الخميسي",
        bio: " يُعرف بالهاكر الصغير الذي تحول إلى مستثمر. كان في صغره من نوعية الأطفال الذين يهابه الأطفال الآخرين في المدرسة.حوّل شجاعته في صغره وقوة شخصية إلى عالم الأعمال ليصبح مغامرًا لا يخشى الخسارة. ",
      },
      {
        name: "إيلون ماسك",
        bio: "مبدع ويتمتع برؤية وقدرة غير عادية، يحب الخيال العلمي ويسعى لتأسيس مشاريع تخدم أحلامه واهتماماته، لديه ميل نحو المخاطرة والاستعداد للسعي وراء فكرة غير تقليدية أو حتى محفوفة بالمخاطر.",
      },
    ],
    movie: {
      title: "The Wolf of wall street",
      url: "https://www.imdb.com/title/tt0993846/?ref_=nv_sr_srsg_0_tt_5_nm_3_q_The%2520Wolf%2520of%2520wall%2520street",
    },
    podcast: {
      title: "الصحة النفسية في بيئة العمل | بودكاست فنجان",
      url: "https://www.youtube.com/watch?v=9JstiMJBfrY",
    },
    book: { title: "الكل يكذب - سيث ستيفنز" },
  },
  dreamer: {
    title: "التاجر الحالم",
    plural: "الحالمون",
    slogan: "الحالم يرسم مستقبلاً مشرقًا",
    desc: `هو تاجر مثالي ومسالم، ینظر للخسارة نظرة إیجابیة، ویتخذ قراره بعد تأني وتدقیق، ویفضل أن ینجح دون أن یخسر غیره￼
      من المنافسین، ویمكنه تجربة طرق عمل جدیدة بشرط إن كانت تؤدي لحلمه، ویفضل أن یكون عمله مبني على اهتماماته الشخصیة 
      `,
    characteristics: "إیجابي، متأنّي، مسالم، یحب المجازفة، عاطفي",
    characters: [
      {
        name: "أضوى الدخيل",
        bio: 'بدأت قصة شغف أضوى الدخيل بالتجارة والأعمال منذ عامها الخامس عشر. المؤسسة والرئيسة التنفيذية لشركة فلك التي اختارت اسمها من الآية الكريمة "كُلٌّ فِي فَلَكٍ يَسْبَحُونَ ". بين جميع المبدعين الرياديين، ويكون هدفنا دفع الاقتصاد لتغيير المجتمع وتغيير العالم.',
      },
      {
        name: "والت ديزني",
        bio: " شغوف بعمله، يقضي ساعات في إنشاء شيء ما، ورسم الرسومات، وكتابة الشخصيات والتفكير، كان أيضًا مدير مهام صعب ولكنه يعرف متى يجب على المرء الاستماع إلى الآخرين ثم تغيير المسار أو النهج وفقًا لذلك.",
      },
    ],
    movie: {
      title: "The Pursuit of Happiness",
      url: "https://www.imdb.com/title/tt0454921/?ref_=nv_sr_srsg_6_tt_7_nm_1_q_The%2520Pursuit%2520of%2520Happiness",
    },
    podcast: {
      title: "سالفة مسرعات الأعمال | بودكاست سوالف بزنس",
      url: "https://www.youtube.com/watch?v=m2YfUdDKF9Y",
    },
    book: { title: "Believe and Achieve - Chris Naylor" },
  },
  sober: {
    title: "التاجر الرزين",
    slogan: "الرزين يصقل القرارات",
    plural: "الرزناء",
    desc: `هو تاجر حریص جدًا، یفضل الأعمال الصغیرة بالربح القلیل على المغامرة بأعمال كبیرة بربح كبیر، یهتم بخطة إدارة
      المخاطر قبل اهتمامه بخطط العمل، ویستطیع التعامل مع متغیرات السوق بدقة، ویفضل النماذج المنطقیة الواضحة والمجربة أكثر من التجارب الشخصیة، ویهتم بالعاملین معه بشكل كبیر جدًا`,
    characteristics:
      "حذر، یحسن اتخاذ الاحتیاطات، محلل، منطقي في العمل، عاطفي مع الموظفین",
    characters: [
      {
        name: "رامي أبو غزالة",
        bio: " الإصرار على النجاح بعد وفاة الوالد وتحقيق الحلم. كانت هنالك خسائر وديون خلفها مشروع مطاعم البروست بعد وفاة والده ولكي يتم سداد الديون عملوا على إكمال مشروع والدهم والنجاح فيه، فمروا بمرحلة صعبة بعنوان التقشف.",
      },
      {
        name: "لبنى العليان",
        bio: "أشهر سيدة أعمال في السعودية، وتدير إمبراطورية تجارية ضخمة باسم مجموعة العليان المالية. في عام 2018، تم تعيين لبنى رئيسة لبنك ساب الأول في المملكة، وهذا يُعتبر حدث غير مسبوق في تاريخ المملكة.",
      },
      {
        name: "جيف بيزوس",
        bio: "يعتقد أنه إذا لم تتمكن من شرح منتجك أو خدماتك بلغة بسيطة،  فهذا يعني أنك لست جاهزًا بعد، وتحتاج إلى العمل على الإعداد مرة أخرى. و يقول لكي تصبح ناجحًا، من المهم أن تفهم المزيد عن السوق.",
      },
    ],
    movie: {
      title: "The Hummingbird Project",
      url: "https://www.imdb.com/title/tt6866224/",
    },
    podcast: {
      title: "كيف تستثمر راتبك لتعيش غنيًا مع صلاح خاشقجي",
      url: "https://www.youtube.com/watch?v=4kxqvBaNmMY",
    },
    book: { title: "إستراتيجية المحيط الأزرق - دبليو ورينيه" },
  },
  wise: {
    title: "التاجر الحكیم",
    slogan: "الحكمة تصنع القادة",
    plural: "الحكماء",
    desc: `هو التاجر الخبیر، یعرف بالضبط مناطق الربح، یمكنه اتخاذ قرارات مصیریة بسرعة ودقة، ویستطیع تفویض المهام دون￼
      تأثر العمل، ویمتلك رؤیة واضحة لمستقبل أعماله، ویفضل التعامل مع لغة الأرقام والإحصائیات لاتخاذ القرارات`,
    characteristics:
      "یمتلك خبرة عالیة في العمل التجاري، سریع البدیهة، مدیر جید، یمتلك مهارة التخطیط، منطقي",
    characters: [
      {
        name: "الوليد بن طلال",
        bio: ' أمير ورجل أعمال سعودي يعد من أكبر المستثمرين في العالم. اختير مرتين من قبل مجلة "فوربس" باعتباره أكثر المستثمرين ذكاءً وإبداعًا في العالم. من المعروف عن أسلوبه أنه ينتهج سياسة اقتناص الفرص.',
      },
      {
        name: "ياسر الرميان",
        bio: " المستشار الشخصي لولي العهد محمد بن سلمان ورفيقه في زياراته الرسمية الخارجية. يرأس الرميان صندوق الاستثمارات العامة الذي تستهدف الحكومة زيادة حجمه إلى تريليوني دولار للإستثمار .",
      },
      {
        name: "د. غادة المطيري",
        bio: " بروفيسورة عالمة وباحثة في الكيمياء وسيدة أعمال سعودية.  تعد أول عالمة عربية في مجال تكنولوجيا النانو حصلت على زمالة كافلي للأكاديمية الوطنية الأمريكية للعلوم عام 2016.",
      },
    ],
    movie: {
      title: "The Intern",
      url: "https://www.imdb.com/title/tt2361509/?ref_=nv_sr_srsg_0_tt_8_nm_0_q_The%2520Intern",
    },
    podcast: {
      title: "أن تكون رائد أعمال قبل وبعد الرؤية | بودكاست سقراط",
      url: "https://www.youtube.com/watch?v=tcYHcaVhb_o",
    },
    book: { title: "خرافة ريادة الأعمال - مايكل غيربر" },
  },
  artist: {
    title: "التاجر الفنان",
    slogan: "الفنان يبتكر ويتجاوز الحدود",
    plural: "الفنانون",

    desc: `هو التاجر المبتكر، هادئ جدًا في أوقات المخاطر المالیة، ویفضل أن یكون منتجه مرتبط بمشاعر الناس، ویحب الأعمال
      الجدیدة والمبتكرة، ویهتم أن یكون منتجه یحل مشكلة لدى الناس، ویمتلك شخصیة ملهمة فیما یخص العمل والظهور الإعلامي`,
    characteristics: "متفائل، عاطفي، متحمس، ملهم، مراعي",
    characters: [
      {
        name: "ريم قراش",
        bio: 'دخلت قطاع السياحة والفندق وأسس فنادق “شدا” بمعايير عالمية تنافس أفضل السلاسل الفندقية العالمية في المملكة، وأحدثها مشروع "شدا بوتيك الجميرة" الأول خارج المملكة. تهتم بالهوية القوية التي تعكس روح وأصالة التراث السعودي.',
      },
      {
        name: "ستيف جوبز",
        bio: " رؤيته الخلاقة والابتكار لإحداث تغيير كبير في صناعة الحواسيب والأدوات الإلكترونية و يميل ستيف إلى التركيز على النتائج ومن المحتمل أن يزدهر في بيئة سريعة الخطى تشجع على المخاطرة. ",
      },
      {
        name: "برنار أرنو",
        bio: "شغفه مكّنه من تحديد الفرص التي فوتها الآخرين، وتمكّن من تحويلها إلى مشاريع مربحة. يؤمن بأهمية بناء شبكة علاقات قوية تساعد في تحقيق الأهداف و يخاطر ويتخذ خطوات كبيرة مع وضع خطة واضحة في الاعتبار.",
      },
    ],
    movie: {
      title: "The Founder",
      url: "https://www.imdb.com/title/tt4276820/?ref_=nv_sr_srsg_0_tt_8_nm_0_q_The%2520Founder",
    },
    podcast: {
      title: "لا تدرس شيء ينتهي بك كموظف” مع نبيل النور | بودكاست فنجان",
      url: "https://www.youtube.com/watch?v=zyRu56uaZuk",
    },
    book: { title: "فن ادارة الاعمال - ستان و ديفيد" },
  },
  fighter: {
    title: "التاجر المكافح",
    slogan: "المكافح يقهر التحديات",
    plural: "المكافحون",
    desc: `هو التاجر العملي، یفضل العمل الجماعي، ویفضل العمل الحركي والبدء مبكرًا، ویتعامل مع الأحداث حال وقوعها بكفاءة 
      دون تخطیط مسبق، ویستطیع اتخاذ قرارات مصیریة بسهولة، ویؤمن بفكرته الأساسیة ویصر على تنفیذها`,
    characteristics: "متعاون، نشیط، مباشر، حاسم، واثق",
    characters: [
      {
        name: "محمد الدوسري",
        bio: " بدأت رغبة محمد في الدخول الى عالم ريادة الاعمال من خلال دراساته التنظيمية وإدارة العمليات، اصبح مستعد حينها لبدء التحدي. مؤسس منصة ساري التي تخدم قطاع الأعمال حيث تقوم بربط الموردين وتجار الجملة  بأصحاب المشاريع.",
      },
      {
        name: "سام والتن",
        bio: "تولى مجموعة متنوعة من الوظائف من أجل المساهمة في دخل عائلته وهذا علمه قيمة العمل الشاق والتصميم في سن مبكرة. رؤيته تكمن في تقليل تكاليف المعيشة للأشخاص الذي يتسوقون من متاجره.",
      },
      {
        name: "محمد يوسف ناغي",
        bio: "رئيس الغرفة التجارية الصناعية بجدة ورجل أعمال وأحد أبرز التجار والمستثمرين في السعودية والوطن العربي.عمل في بدايته في المستودعات ليفهم حركة البضائع ونوعها. اكتسب خبرته بالعمل مع والده وعمه.",
      },
    ],
    movie: {
      title: "CODA",
      url: "https://www.imdb.com/title/tt10366460/?ref_=nv_sr_srsg_0_tt_8_nm_0_q_Coda",
    },
    podcast: {
      title: "بودكاست مثلث || من مشاريع فاشلة إلى رائد أعمال",
      url: "https://www.youtube.com/watch?v=r6717TbJzr0",
    },
    book: { title: "تحويل الابتكار الى أموال - مادهافن رامانوجام" },
  },
};

export default TypeContent;
