import typesSketch from "./assets/images/typesSketch.png";
import typesSketchMobile from "./assets/images/typesSketchMobile.png";
import SallaLogo from "./assets/images/SallaLogo.svg";
import homepageVector from "./assets/images/homepageVector.svg";
import { Link, useNavigate } from "react-router-dom";
import { MobileContext } from "./context/MobileContext";
import { useContext, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import YoutubeIconHomePage from "./components/YoutubeIconHomePage";
import TwitterIconHomePage from "./components/TwitterIconHomePage";
import InstagramIconHomePage from "./components/InstagramIconHomePage";
import CloseIcon from "./components/CloseIcon";
import EmailIcon from "./components/EmailIcon";
import FacebookIcon from "./components/FacebookIcon";
import LinkIcon from "./components/LinkIcon";
import TwitterIcon from "./components/TwitterIcon";
import WhatsappIcon from "./components/WhatsappIcon";
import ShareIconHomePage from "./components/ShareIconHomePage";

export default function App() {
  const isMobile = useContext(MobileContext);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageUrl = typesSketch;
  const title = "اكتشف شخصيتك التجارية";
  const description = "تعرف على ملامح شخصیتك التجاریة وكیفیة استثمارها";
  const ogImageUrl = imageUrl || typesSketch;
  const ogTitle = title || "اكتشف شخصيتك التجارية";
  const ogDescription =
    description || "تعرف على ملامح شخصیتك التجاریة وكیفیة استثمارها";

  document
    ?.querySelector('meta[property="og:image"]')
    ?.setAttribute("content", ogImageUrl);
  document
    ?.querySelector('meta[property="og:title"]')
    ?.setAttribute("content", ogTitle);
  document
    ?.querySelector('meta[property="og:description"]')
    ?.setAttribute("content", ogDescription);
  document
    ?.querySelector('meta[name="twitter:image"]')
    ?.setAttribute("content", ogImageUrl);
  document
    ?.querySelector('meta[name="twitter:title"]')
    ?.setAttribute("content", ogTitle);
  document
    ?.querySelector('meta[name="twitter:description"]')
    ?.setAttribute("content", ogDescription);

  const shareOnTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      ogTitle,
    )}&url=${encodeURIComponent(window.location.href)}`;
    window.open(twitterShareUrl, "_blank");
  };

  const shareOnWhatsApp = () => {
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      `${ogTitle} ${window.location.href}`,
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const shareOnFacebook = () => {
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      window.location.href,
    )}`;
    window.open(facebookShareUrl, "_blank");
  };

  const shareLink = () => {
    const contentToCopy = `${ogTitle} - ${window.location.href}`;
    navigator.clipboard.writeText(contentToCopy);
  };

  const shareOnEmail = () => {
    const emailSubject = encodeURIComponent(ogTitle);
    const emailBody = encodeURIComponent(
      `${ogTitle}\n\n${window.location.href}`,
    );
    window.location.href = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  };

  const handleGoToLibrary = () => {
    navigate("./figures", { state: { from: "home" } });
  };

  return (
    <main className="homepage__section flex min-h-screen flex-col items-center justify-between lg:p-5 xl:p-15">
      <div className="homepage__section--logo">
        <a href="https://salla.com">
          <img alt="منصة سلة للتجارة الإلكترونية" src={SallaLogo} />
        </a>
      </div>
      <div className="homepage__section--vector">
        {isMobile ? null : (
          <img alt="منصة سلة للتجارة الإلكترونية" src={homepageVector} />
        )}
      </div>
      <div className="homepage__section--content">
        <h1>اكتشف شخصيتك التجارية</h1>
        <h2>تعرف على ملامح شخصیتك التجاریة وكیفیة استثمارها</h2>
        <Link
          className="ButtonComponent"
          style={{ lineHeight: "66px" }}
          to="/loader/start"
        >
          &larr; ابدأ
        </Link>
        {isMobile ? (
          <div>
            <Button
              style={{ fontSize: "16px" }}
              className="sharePageButton"
              onClick={handleGoToLibrary}
            >
              مكتبة الشخصيات التجارية
            </Button>
          </div>
        ) : null}
      </div>
      <div className="homepage__section--typesSketch z-10 w-full max-w-5xl items-center justify-between font-mono text-sm lg:flex">
        {isMobile ? (
          <img
            className="mobileSketch"
            src={typesSketchMobile}
            alt="اكتشف شخصيتك التجارية"
          />
        ) : (
          <img alt="اكتشف شخصيتك التجارية" src={typesSketch} />
        )}
      </div>
      <div className="homepage__section--share">
        <div className="homepage__section--share--socialIcons">
          <Stack direction={"row"} gap={1}>
            <a href="https://www.youtube.com/c/SallaApp">
              <YoutubeIconHomePage />
            </a>
            <a href="https://www.twitter.com/sallaapp?lang=ar">
              <TwitterIconHomePage />
            </a>
            <a href="https://www.instagram.com/sallaapp/?utm_source=salla.sa&utm_referer=salla.sa">
              <InstagramIconHomePage />
            </a>
            <a style={{ marginLeft: "3px" }} href="#" onClick={handleClickOpen}>
              <ShareIconHomePage />
            </a>
          </Stack>
        </div>
        {isMobile ? null : (
          <div>
            <Button
              className="sharePageButton shareBf"
              onClick={handleGoToLibrary}
            >
              مكتبة الشخصيات التجارية
            </Button>
          </div>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="salla-dialog"
        >
          <DialogTitle id="alert-dialog-title" className="--bg-salla">
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </DialogTitle>
          <DialogContent>
            <h1 className="result__section--contentSection--title mt-10">
              شارك أصدقائك
            </h1>
            <DialogContentText
              id="alert-dialog-description"
              className="--salla-dialog-content"
            >
              يمكنك مشاركة مع جمهورك وأصدقائك عبر مواقع التواصل الاجتماعي
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Stack
              className="share-on-socialmedia-buttons"
              gap={isMobile ? 0 : 2}
              direction={"row"}
              margin={"20px auto"}
              justifyContent={"space-around"}
              flexWrap={"wrap"}
            >
              <Button onClick={shareOnWhatsApp}>
                <WhatsappIcon />
              </Button>
              <Button onClick={shareOnTwitter}>
                <TwitterIcon />
              </Button>
              <Button onClick={shareOnFacebook}>
                <FacebookIcon />
              </Button>
              <Button onClick={shareLink}>
                <LinkIcon />
              </Button>
              <Button onClick={shareOnEmail}>
                <EmailIcon />
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      </div>
    </main>
  );
}
