import PlayerIcon from "../assets/images/playerIcon.svg";
export interface MediaType {
  src: string | undefined;
  type: string;
  title: string | undefined;
  url?: string;
}

const MediaComponent = (props: MediaType) => {
  const mediaContent = (
    <div
      className="__mediaComponent"
      style={{ background: `url("${props.src}")` }}
    >
      <div className="contentType">
        {props.type === "movie" && (
          <div className="__mediaComponent--mediaType">افلام</div>
        )}
        {props.type === "podcast" && (
          <div className="__mediaComponent--mediaType">بودكاست</div>
        )}
        {props.type === "book" && (
          <div className="__mediaComponent--mediaType">كتاب</div>
        )}
        {(props.type === "movie" || props.type === "podcast") && (
          <img className="__mediaComponent--Player" src={PlayerIcon} alt="" />
        )}
        <div className="__mediaComponent--contentTitle">{props.title}</div>
      </div>
    </div>
  );
  if (props.url) {
    return <a href={props.url}>{mediaContent}</a>;
  }
  return mediaContent;
};

export default MediaComponent;
