import { SVGAttributes, SVGProps } from "react";

const ClockIcon = (props: SVGAttributes<SVGProps<SVGSVGElement>>) => {
  return (
    <svg
      style={props.style}
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 0C9.60417 0 10.6406 0.208333 11.6094 0.625C12.5781 1.05208 13.4219 1.63021 14.1406 2.35938C14.8698 3.07812 15.4479 3.92188 15.875 4.89062C16.2917 5.85938 16.5 6.89583 16.5 8C16.5 9.10417 16.2917 10.1406 15.875 11.1094C15.4479 12.0781 14.8698 12.9271 14.1406 13.6562C13.4219 14.375 12.5781 14.9479 11.6094 15.375C10.6406 15.7917 9.60417 16 8.5 16C7.39583 16 6.35938 15.7917 5.39062 15.375C4.42188 14.9479 3.57292 14.375 2.84375 13.6562C2.125 12.9271 1.55208 12.0781 1.125 11.1094C0.708333 10.1406 0.5 9.10417 0.5 8C0.5 6.89583 0.708333 5.85938 1.125 4.89062C1.55208 3.92188 2.125 3.07812 2.84375 2.35938C3.57292 1.63021 4.42188 1.05208 5.39062 0.625C6.35938 0.208333 7.39583 0 8.5 0ZM8.5 14.6719C9.41667 14.6719 10.2812 14.4948 11.0938 14.1406C11.9062 13.7969 12.6146 13.3229 13.2188 12.7188C13.8229 12.1146 14.2969 11.4062 14.6406 10.5938C14.9948 9.78125 15.1719 8.91667 15.1719 8C15.1719 7.08333 14.9948 6.21875 14.6406 5.40625C14.2969 4.59375 13.8229 3.88542 13.2188 3.28125C12.6146 2.67708 11.9062 2.20312 11.0938 1.85938C10.2812 1.50521 9.41667 1.32812 8.5 1.32812C7.58333 1.32812 6.71875 1.50521 5.90625 1.85938C5.09375 2.20312 4.38542 2.67708 3.78125 3.28125C3.17708 3.88542 2.70312 4.59375 2.35938 5.40625C2.00521 6.21875 1.82812 7.08333 1.82812 8C1.82812 8.91667 2.00521 9.78125 2.35938 10.5938C2.70312 11.4062 3.17708 12.1146 3.78125 12.7188C4.38542 13.3229 5.09375 13.7969 5.90625 14.1406C6.71875 14.4948 7.58333 14.6719 8.5 14.6719ZM8.5 4C8.6875 4 8.84375 4.06771 8.96875 4.20312C9.10417 4.32812 9.17188 4.48438 9.17188 4.67188V8.67188C9.17188 8.84896 9.10417 9.00521 8.96875 9.14062C8.84375 9.26562 8.6875 9.32812 8.5 9.32812H5.82812C5.65104 9.32812 5.49479 9.26562 5.35938 9.14062C5.23438 9.00521 5.17188 8.84896 5.17188 8.67188C5.17188 8.48438 5.23438 8.32812 5.35938 8.20312C5.49479 8.06771 5.65104 8 5.82812 8H7.82812V4.67188C7.82812 4.48438 7.89062 4.32812 8.01562 4.20312C8.15104 4.06771 8.3125 4 8.5 4Z"
        fill="#777777"
      />
    </svg>
  );
};

export default ClockIcon;
