import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Figure from "../components/Figure";
import TypeContent from "../modules/TypeContent";
import { useContext } from "react";
import { MobileContext } from "../context/MobileContext";
import { useLocation } from "react-router-dom";

const Figures = () => {
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);
  const location = useLocation();

  return (
    <div className="figure__section">
      <h1>الشخصيات الملهمة</h1>
      <h3>تعرف على ابرز الشخصيات الملهمة و التي ترتبط بشخصيتك التجارية</h3>

      {Object.values(TypeContent).map((item) => (
        <div key={item?.title}>
          <div className="figure__section--title_section">
            <h1>{item.plural}</h1>
            <h3>{item.slogan}</h3>
          </div>

          <div className="lg:flex justify-center result__section--contentSection--chars">
            {item?.characters?.map((c) => {
              return <Figure key={c?.name} character={c} />;
            })}
          </div>
        </div>
      ))}

      <div className="figure__section--figuresButton">
        {location.state.from === "home" ? (
          <Stack
            direction={isMobile ? "column" : "row"}
            mt={5}
            gap={3}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ fontSize: "16px" }}
              onClick={() => navigate(-1)}
            >
              العودة للصفحة الرئيسية
            </Button>
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ background: "white!important", fontSize: "16px" }}
              onClick={() => navigate("/loader/start")}
            >
              ابدأ الاختبار
            </Button>
          </Stack>
        ) : (
          <Stack
            direction={isMobile ? "column" : "row"}
            mt={5}
            gap={3}
            justifyContent="center"
            alignItems="center"
          >
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ fontSize: "16px" }}
              onClick={() => navigate(-1)}
            >
              العودة لنتيجتك
            </Button>
            <Button
              className="ButtonComponent"
              variant="contained"
              color="primary"
              sx={{ background: "white!important", fontSize: "16px" }}
              onClick={() => navigate("/start")}
            >
              اختبر من جديد
            </Button>
          </Stack>
        )}
      </div>
    </div>
  );
};

export default Figures;
