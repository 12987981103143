import React from "react";

const NextIcon = (props: { style: React.CSSProperties | undefined }) => {
  return (
    <svg
      style={props.style}
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.4175 7.74549C20.7182 7.74549 20.9687 7.85408 21.1692 8.07125C21.3864 8.27173 21.495 8.52232 21.495 8.82302C21.495 9.12373 21.3864 9.38267 21.1692 9.59985C20.9687 9.80032 20.7182 9.90056 20.4175 9.90056H4.02889L10.4189 15.5138C10.6528 15.6975 10.7781 15.9398 10.7948 16.2405C10.8115 16.5245 10.7196 16.7834 10.5192 17.0173C10.4189 17.1342 10.2936 17.2261 10.1433 17.293C10.0096 17.3431 9.86763 17.3681 9.71728 17.3681C9.60033 17.3681 9.47504 17.3514 9.34139 17.318C9.22445 17.2679 9.11586 17.2011 9.01562 17.1175L0.470519 9.62491C0.470519 9.62491 0.462166 9.62491 0.44546 9.62491C0.44546 9.6082 0.44546 9.59985 0.44546 9.59985C0.412048 9.56644 0.378636 9.53303 0.345224 9.49962C0.311812 9.4495 0.286753 9.39938 0.270047 9.34926C0.253341 9.33256 0.236635 9.31585 0.219929 9.29914C0.219929 9.28244 0.211576 9.26573 0.19487 9.24903C0.161458 9.1822 0.136399 9.11538 0.119693 9.04855C0.102987 8.98173 0.0946343 8.90655 0.0946343 8.82302C0.0946343 8.73949 0.102987 8.66432 0.119693 8.59749C0.136399 8.53067 0.161458 8.46384 0.19487 8.39702C0.19487 8.38031 0.203223 8.36361 0.219929 8.3469C0.236635 8.3302 0.253341 8.31349 0.270047 8.29679C0.286753 8.24667 0.311812 8.2049 0.345224 8.17149C0.378636 8.12137 0.412048 8.07961 0.44546 8.0462C0.462166 8.02949 0.470519 8.02114 0.470519 8.02114L9.01562 0.528507C9.24951 0.344741 9.50845 0.261211 9.79245 0.277917C10.0932 0.294623 10.3354 0.411565 10.5192 0.628743C10.7196 0.862626 10.8115 1.12992 10.7948 1.43063C10.7781 1.71463 10.6528 1.94851 10.4189 2.13228L4.02889 7.74549H20.4175Z"
        fill="#004956"
      />
    </svg>
  );
};

export default NextIcon;
