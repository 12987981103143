import { useContext, useEffect, useState } from "react";
import { MobileContext } from "../context/MobileContext";
import {
  Box,
  LinearProgress,
  Stack,
  Typography,
  linearProgressClasses,
} from "@mui/material";
import ClockIcon from "../components/ClockIcon";
import BrainIcon from "../components/BrainIcon";
import HandIcon from "../components/HandIcon";
import LoadingSketch from "../assets/images/characters/sober.png";
import sketchSrc from "../assets/resultsCharacters/fighter/sketch.png";
import sketchSrcMobile from "../assets/resultsCharacters/wise/sketch.png";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Navbar from "../components/Navbar.tsx";

const Loader = () => {
  const [redirect, setRedirect] = useState(false);
  const [progress, setProgress] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useContext(MobileContext);
  const { page } = useParams();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (redirect) {
          clearInterval(timer);
          return prevProgress;
        }
        const nextProgress = prevProgress >= 100 ? 0 : prevProgress + 1;
        setRedirect(nextProgress < 100 ? false : true);
        return nextProgress;
      });
    }, 100);

    return () => {
      clearInterval(timer);
    };
  }, [redirect]);

  useEffect(() => {
    if (redirect) {
      if (page === "start") {
        navigate("../start");
      } else {
        navigate("../result/" + location.state.type);
      }
    }
  }, [location?.state?.type, navigate, page, redirect]);

  if (page === "start") {
    return (
      <>
        <Navbar></Navbar>
        <div className="result__section">
          {isMobile ? (
            <img className="mobileSketch" src={sketchSrcMobile} alt="" />
          ) : null}
          <div className="flex">
            <div className="flex-1 result__section--contentSection--loading">
              <div className="result__section--contentSection--InnercontentSection">
                <h1>استعد..!</h1>
                <h2>تعرف على ملامح شخصیتك التجاریة وكیفیة استثمارها </h2>

                <div>
                  <Box mt={5}>
                    <LinearProgress
                      sx={{
                        backgroundColor: "#EEEEEE",
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: "15px",
                          backgroundColor: "#004956",
                        },
                      }}
                      style={{
                        transform: "rotate(180deg)",
                        width: "350px",
                        height: "12.5px",
                        borderRadius: "15px",
                        margin: "0 auto",
                      }}
                      variant="determinate"
                      value={progress}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Typography
                      className="start__section--LoaderProgress"
                      variant="body2"
                    >{`${progress}%`}</Typography>
                  </Box>
                </div>

                <Stack
                  className="grstack"
                  spacing={{ xs: 2, sm: 3 }}
                  justifyContent={"center"}
                  direction={"row"}
                  useFlexGap
                  flexWrap="wrap"
                >
                  <div>
                    <ClockIcon
                      style={{ display: "inline-block", margin: "5px" }}
                    />
                    مدة الاختبار ١٠ دقائق
                  </div>

                  <div>
                    <BrainIcon
                      style={{ display: "inline-block", margin: "5px" }}
                    />
                    عدد الاسئلة ٣٠ سؤال
                  </div>
                  <div style={{ width: "100%" }}>
                    <HandIcon
                      style={{ display: "inline-block", margin: "5px" }}
                    />
                    اختار الإجابة الأقرب لواقعك وليست المثالية
                  </div>
                </Stack>
                <div className="result__section--contentSection--title-container">
                  <h1 className="result__section--contentSection--title-container--title">
                    ملاحظة
                  </h1>
                  <h3>
                    هذا الاختبار صمّم للاحتفاء بسمات شخصيتك التجارية ولا يعتمد
                    لتشخيص حالتك النفسية أو اتخاذ قرارات مهنية بناء عليه.
                  </h3>
                </div>
              </div>
            </div>
            {isMobile ? null : (
              <div className="result__section--characterSection">
                <div className="result__section--characterSection--loading">
                  <img src={sketchSrc} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Navbar></Navbar>
        <div className="result__section">
          {isMobile ? (
            <img className="mobileSketch" src={LoadingSketch} alt="" />
          ) : null}
          <div className="flex">
            <div className="flex-1 result__section--contentSection--loading">
              <div className="result__section--contentSection--InnercontentSection">
                <h1
                  style={
                    isMobile ? { marginTop: "10px" } : { marginTop: "21vh" }
                  }
                >
                  شخصيتك هي..
                </h1>
                <h2>انتظر قليلاً..لحظات و يتم تجهيز نتيجتك الابداعية</h2>

                <div>
                  <Box mt={5}>
                    <LinearProgress
                      sx={{
                        backgroundColor: "#EEEEEE",
                        [`& .${linearProgressClasses.bar}`]: {
                          borderRadius: "15px",
                          backgroundColor: "#004956",
                        },
                      }}
                      style={{
                        transform: "rotate(180deg)",
                        width: "350px",
                        height: "12.5px",
                        borderRadius: "15px",
                        margin: "0 auto",
                      }}
                      variant="determinate"
                      value={progress}
                    />
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={1}
                  >
                    <Typography
                      className="start__section--LoaderProgress"
                      variant="body2"
                    >{`${progress}%`}</Typography>
                  </Box>
                </div>
              </div>
            </div>
            {isMobile ? null : (
              <div className="result__section--characterSection">
                <div className="result__section--characterSection--loading">
                  <img src={LoadingSketch} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
};

export default Loader;
